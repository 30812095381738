// Generated by Framer (c93245f)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["RgXBMbgSj"];

const serializationHash = "framer-f0PwP"

const variantClassNames = {RgXBMbgSj: "framer-v-5vn0wo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "RgXBMbgSj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-5vn0wo", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RgXBMbgSj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1m28dcn"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Xrnz2c0ON-shape"} opacity={1} style={{backgroundColor: "rgb(255, 255, 255)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 274 81\"><path d=\"M 0 15 C 0 6.716 6.716 0 15 0 L 259 0 C 267.284 0 274 6.716 274 15 L 274 66 C 274 74.284 267.284 81 259 81 L 15 81 C 6.716 81 0 74.284 0 66 Z\" fill=\"#CCC\"></path></svg>"} svgContentId={3945365802} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-f0PwP.framer-sa91pw, .framer-f0PwP .framer-sa91pw { display: block; }", ".framer-f0PwP.framer-5vn0wo { height: 81px; overflow: hidden; position: relative; width: 274px; }", ".framer-f0PwP .framer-1m28dcn { flex: none; height: 81px; left: calc(50.00000000000002% - 274px / 2); position: absolute; top: calc(49.382716049382736% - 81px / 2); width: 274px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 81
 * @framerIntrinsicWidth 274
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhvWmyxAYv: React.ComponentType<Props> = withCSS(Component, css, "framer-f0PwP") as typeof Component;
export default FramerhvWmyxAYv;

FramerhvWmyxAYv.displayName = "Button Graphic";

FramerhvWmyxAYv.defaultProps = {height: 81, width: 274};

addFonts(FramerhvWmyxAYv, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})